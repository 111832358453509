<!-- eslint-disable -->
<template>
  <v-app-bar
    id="app-bar" absolute app color="#007bff00" flat height="50"
  >
    <b-modal v-model="showLoginModal" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Se Connecter
      </template>
        <b-form @submit.prevent="login">
          <v-container class="py-0">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field required v-model="email" label="Adresse Email ou Numéro de téléphone" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field required v-model="password" type="password" label="Password" class="purple-input"/>
              </v-col>
              <div style="width:100%">
                  <p v-if="loginMessage.show && loginMessage.status == 'error'" style="color:red">{{loginMessage.message}}</p>
              </div>
              <v-col cols="12" class="text-right">
                <v-btn type="submit"  style="background-color:#4caf50;border-color:#4caf50" class="mr-0">
                  Se Connecter
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </b-form>
    </b-modal>
    <b-modal v-model="showCenterLogin" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Se CONNECTER
      </template>
      <b-form @submit.prevent="centerLogin">
        <v-container class="py-0">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field required v-model="email" label="Adresse Email ou Numéro de téléphone" class="purple-input"/>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field required v-model="password" type="password" label="Password" class="purple-input"/>
            </v-col>
            <div style="width:100%">
              <p v-if="loginMessage.show && loginMessage.status == 'error'" style="color:red">{{loginMessage.message}}</p>
            </div>
            <v-col cols="12" class="text-right">
              <v-btn type="submit"  style="background-color:#4caf50;border-color:#4caf50" class="mr-0">
                Se Connecter
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </b-form>
    </b-modal>
    <b-modal v-model="showEntrepriseLogin" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Se CONNECTER
      </template>
        <b-form @submit.prevent="providerlogin">
          <v-container class="py-0">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field required v-model="email" label="Adresse Email ou Numéro de téléphone" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field required v-model="password" type="password" label="Password" class="purple-input"/>
              </v-col>
              <div style="width:100%">
                  <p v-if="loginMessage.show && loginMessage.status == 'error'" style="color:red">{{loginMessage.message}}</p>
              </div>
              <v-col cols="12" class="text-right">
                <v-btn type="submit"  style="background-color:#4caf50;border-color:#4caf50" class="mr-0">
                  Se Connecter
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </b-form>
    </b-modal>
    <b-modal v-model="showEntrepriseSignup" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Se CONNECTER
      </template>
      <b-form @submit.prevent="addprovider('provider')">
        <v-container class="py-0">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field required class="purple-input" v-model="name" label="Nom entreprise"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="semail" label="Adresse Email ou Numéro de téléphone" class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="spassword" label="Password" type="password" class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="phone" label="Telephone" placeholder="phone1/phone2"  class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <label>Wilaya</label>
            <b-form-select required v-model="selectedWilaya" @change="wilaya = selectedWilaya.name">
              <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
            </b-form-select>
            </v-col>
            <v-col cols="12" md="6">
              <label>Commune</label>
            <b-form-select required v-model="selectedCommunue" @change="communue = selectedCommunue">
              <option v-for="communue in selectedWilaya.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
            </b-form-select>
            </v-col>
            <!-- <v-col cols="12">
              <label>Poste </label>
              <b-form-select required v-model="selectedPost" @change="wilaya = selectedWilaya.name">
                <option v-for="postCenter in postCenters" :key="postCenter.code" :value="postCenter.code">{{postCenter.name}}</option>
              </b-form-select>
            </v-col> -->
            <v-col cols="12">
              <v-text-field required v-model="address" label="Adresse" class="purple-input"/>
            </v-col>
            <!-- <v-col cols="12" md="6">
              <v-text-field v-model="wilaya" label="wilaya" class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="communue" label="communue" class="purple-input"/>
            </v-col> -->
            <v-col cols="12" class="text-right">
            <div v-if="emailexist=='error'" style="text-align:center">
              <p style="color:red;">Ce Mail existe déja</p>
            </div>
            <div v-if="emailexist=='success'" style="text-align:center">
              <p style="color:green;">Votre requête à été envoyé</p>
            </div>
              <v-btn type="submit"  style="background-color:#4caf50;border-color:#4caf50" class="mr-0">
                Envoyer
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </b-form>
    </b-modal>
    <b-modal v-model="showCenterSignup" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Se CONNECTER
      </template>
      <b-form @submit.prevent="addprovider('logistic')">
        <v-container class="py-0">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field required class="purple-input" v-model="name" label="Nom entreprise"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="semail" label="Adresse Email ou Numéro de téléphone" class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="spassword" label="Password" type="password" class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="phone" label="Telephone" placeholder="phone1/phone2"  class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <label>Wilaya</label>
            <b-form-select required v-model="selectedWilaya" @change="wilaya = selectedWilaya.name">
              <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
            </b-form-select>
            </v-col>
            <v-col cols="12" md="6">
              <label>Commune</label>
            <b-form-select required v-model="selectedCommunue" @change="communue = selectedCommunue">
              <option v-for="communue in selectedWilaya.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
            </b-form-select>
            </v-col>
            <!-- <v-col cols="12">
              <label>Poste </label>
              <b-form-select required v-model="selectedPost" @change="wilaya = selectedWilaya.name">
                <option v-for="postCenter in postCenters" :key="postCenter.code" :value="postCenter.code">{{postCenter.name}}</option>
              </b-form-select>
            </v-col> -->
            <v-col cols="12">
              <v-text-field required v-model="address" label="Adresse" class="purple-input"/>
            </v-col>
            <!-- <v-col cols="12" md="6">
              <v-text-field v-model="wilaya" label="wilaya" class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="communue" label="communue" class="purple-input"/>
            </v-col> -->
            <v-col cols="12" class="text-right">
            <div v-if="emailexist=='error'" style="text-align:center">
              <p style="color:red;">Ce Mail existe déja</p>
            </div>
            <div v-if="emailexist=='success'" style="text-align:center">
              <p style="color:green;">Votre requête à été envoyé</p>
            </div>
              <v-btn type="submit"  style="background-color:#4caf50;border-color:#4caf50" class="mr-0">
                Envoyer
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </b-form>
    </b-modal>
    <a class="" style="text-decoration:none;" href="/">
    <!-- <v-toolbar-title
      class="hidden-sm-and-down font-weight-light "
      v-text="'TLM'"
    /> -->
    <v-list-item-avatar
      class="align-self-center"
      color="white"
      contain
    >
    <v-img
      src="../../../../assets/logo.jpeg"
      max-height="30"
    />
    </v-list-item-avatar>
    <span class="hidden-sm-and-down" style="color:black">TLM</span>
    </a>
    <!-- <v-btn style="text-transform:none;text-decoration:none;" class="hidden-sm-and-down ml-2 " min-width="0" text to="/">

    </v-btn> -->
    <v-spacer />
      <!-- <v-btn style="text-transform:none;text-decoration:none;" class="hidden-sm-and-down ml-2 " min-width="0" text href="mailto:tlmexpress@gmail.com">
        <v-icon class="">mdi-email</v-icon>{{'\xa0'}}{{'\xa0'}} {{'\xa0'}}tlmexpress@gmail.com
      </v-btn>
      <v-btn style="text-transform:none;text-decoration:none;" class="hidden-sm-and-down ml-2 " min-width="0" text href="tel:+213777205205">
        <v-icon class="">mdi-phone</v-icon>{{'\xa0'}}{{'\xa0'}}{{'\xa0'}}(+213){{'\xa0'}}{{'\xa0'}}0777 20 52 05 / 0655 51 67 02
      </v-btn> -->
    <a class="hidden-sm-and-down home-menu-item" href="/">Acceuil</a>
    <div @mouseover="hover($refs.dropdown0)" @mouseleave="unhover($refs.dropdown0)" style="font-size:16px;" class="hidden-sm-and-down">
      <b-dropdown ref="dropdown0" id="dropdown-0" text="Présentation" class="custom-btn" variant="transparent">
        <b-dropdown-item href="/about-us">Qui somme nous?</b-dropdown-item>
      </b-dropdown>
    </div>
    <div @mouseover="hover($refs.dropdown1)" @mouseleave="unhover($refs.dropdown1)" class="hidden-sm-and-down">
      <b-dropdown ref="dropdown1"  id="dropdown-1" text="Logistique" class="custom-btn"  variant="transparent">
        <b-dropdown-item @click="showEntrepriseSignup=true">Inscription</b-dropdown-item>
        <b-dropdown-item @click="showEntrepriseLogin=true">Se Connecter</b-dropdown-item>
      </b-dropdown>
    </div>
    <div @mouseover="hover($refs.dropdown2)" @mouseleave="unhover($refs.dropdown2)" class="hidden-sm-and-down">
      <b-dropdown ref="dropdown2"  id="dropdown-2" text="TLM Express" class="custom-btn" variant="transparent">
        <b-dropdown-item @click="showCenterSignup=true">Inscription</b-dropdown-item>
        <b-dropdown-item @click="showCenterLogin=true">Se Connecter</b-dropdown-item>
      </b-dropdown>
    </div>
    <div @mouseover="hover($refs.dropdown3)" @mouseleave="unhover($refs.dropdown3)" class="hidden-sm-and-down">
      <b-dropdown ref="dropdown3"  id="dropdown-3" text="TLM S.O.S" class="custom-btn" variant="transparent">
        <b-dropdown-item>Inscription</b-dropdown-item>
        <b-dropdown-item>Se Connecter</b-dropdown-item>
      </b-dropdown>
    </div>
    <router-link to="/contact-us"> <a class="hidden-sm-and-down home-menu-item">Contacter Nous</a></router-link>
    <v-spacer />
    <v-spacer />
    <div class="mx-3" />
    <a style="margin-bottom:5px;text-decoration: none;" target="_blank" href="">
      <v-icon style="color:black">mdi-instagram</v-icon>
    </a>
    <div class="mx-3" />
    <a style="margin-bottom:5px;text-decoration: none;" target="_blank" href="">
      <v-icon style="color:black">mdi-facebook</v-icon>
    </a>
    <v-btn
      class="ml-2 "
      min-width="0"
      text
      @click="showLoginModal=true"
      v-b-tooltip.hover title="Espace admin"
    >
      <v-icon style="color:black">mdi-account</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
/* eslint-disable */
  // Components
  import { VHover, VListItem } from 'vuetify/lib'
  import axios from 'axios'
  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'HomeBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    ' secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      wilayas: [],
      communues: [],
      email: null,
      password:null,
      showEntrepriseLogin: false,
      showCenterLogin: false,
      showEntrepriseSignup:false,
      showCenterSignup: false,
      showLoginModal:false,
      loginMessage: {
        show:false,
        status: null,
        message: null
      },
      selectedWilaya: {
        name:null,
        communues: []
      },
      selectedCommunue: null,
      trackingMessage: {
        show: false,
        status: null,
        message: null
      },
      loginMessage: {
        show: false,
        status: null,
        message: null
      },
      tracking_id: null,
      showPackageModal: false,
      messageWhenNoItems: 'There are not items',
      timelineItems: [
        // {
        //   from: new Date(2018, 7,1),
        //   title: 'Name',
        //   description:
        //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.'
        // },
      ],
      messagereseved: null,
      cemail: null,
      cname: null,
      telephone: null,
      text: null,
      emailexist: null,
      semail: null,
      spassword: null,
      name: null,
      address: null,
      phone: null,
      wilaya: null,
      communue: null,
      email: null,
      auth_token: null,
      password: null,
      postCenters: null,
      selectedPost: 'ANB',
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        'You\'re now friends with Andrew',
        'Another Notification',
        'Another one',
      ],
    }),
    mounted() {
      axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
      this.getWilayas()
    },
    computed: {
      ...mapState(['drawer']),
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      getWilayas() {
        axios.post('get-wilayas').then(response => {
          if (response.data.status == 'success') {
            this.wilayas = response.data.data
          }
        })
      },
      providerlogin() {
        axios.post('login', {
          email: this.email,
          password: this.password
        }).then(response => {
          if (response.data.status =='success') {
            this.auth_token = response.data.data.auth_token
            localStorage.setItem('auth_token',response.data.data.auth_token)
            localStorage.setItem('user_type',response.data.data.type)
            this.$router.push({name:'Dashboard'})
          } else {
              console.log(response.data.error_message)
              this.loginMessage = {
                status: response.data.status,
                message: response.data.error_message,
                show: true
              }
          }
        })
      },
      centerLogin() {
        axios.post('center-login', {
          email: this.email,
          password: this.password
        }).then(response => {
          if (response.data.status =='success') {
            this.auth_token = response.data.data.auth_token
            localStorage.setItem('auth_token',response.data.data.auth_token)
            localStorage.setItem('user_type',response.data.data.type)
            this.$router.push({name:'CenterDashboard'})
            } else {
              console.log(response.data.error_message)
              this.loginMessage = {
                status: response.data.status,
                message: response.data.error_message,
                show: true
              }
            }
        })
      },
      centerLogin() {
        axios.post('center-login', {
          email: this.email,
          password: this.password
        }).then(response => {
          if (response.data.status =='success') {
            this.auth_token = response.data.data.auth_token
            localStorage.setItem('auth_token',response.data.data.auth_token)
            localStorage.setItem('user_type',response.data.data.type)
            this.$router.push({name:'CenterDashboard'})
            } else {
              console.log(response.data.error_message)
              this.loginMessage = {
                status: response.data.status,
                message: response.data.error_message,
                show: true
              }
            }
        })
      },
      login() {
        axios.post('admin-login', {
          email: this.email,
          password: this.password
        }).then(response => {
          if (response.data.status =='success') {
            this.auth_token = response.data.data.auth_token
            localStorage.setItem('auth_token',response.data.data.auth_token)
            localStorage.setItem('user_type',response.data.data.type)
            if (response.data.data.type == 'admin') {
              this.$router.push({name:'AdminDashboard'})
            } else if (response.data.data.type == 'driver') {
             this.$router.push({name:'DriverDashboard'})
            } else if (response.data.data.type == 'depot') {
              this.$router.push({name:'DepotDashboard'})
            } else if (response.data.data.type == 'delivery_man') {
              this.$router.push({name:'DeliveryManDashboard'})
            }
          } else {
              console.log(response.data.error_message)
              this.loginMessage = {
                status: response.data.status,
                message: response.data.error_message,
                show: true
              }
          }
        })
      },
      addprovider(type) {
        axios.post('addprovider',{
          semail: this.semail,
          spassword: this.spassword,
          name: this.name,
          phone: this.phone,
          address: this.address,
          communue: this.communue,
          wilaya: this.wilaya,
          post: this.selectedPost,
          type:type
        }).then(response => {
          this.emailexist = response.data.status
          this.semail=null
          this.spassword=null
          this.name=null
          this.phone=null
          this.address=null
          this.selectedWilaya= {
            name:null,
            communues: []
          }
          this.selectedCommunue=null
          this.selectedPost = null
        })

      },
      handleModalHide() {
        this.showLoginModal = false
      },
      hover(element) {
        element.visible = true
      },
      unhover(element) {
        element.visible = false
      }
    },
  }
</script>
<style>
.home-menu-item {
  text-decoration: none !important;
  font-size:16px;
  font-weight:400;
  padding:10px 20px;
  color:black !important;
  border-radius:0.25rem;
}
.home-menu-item:hover {
  background-color: #0EAEDA;
}
.dropdown:hover {
  background-color: #0EAEDA;
  /* padding: 10px 20px; */
}
.custom-btn button{
  padding: 10px 20px;
  font-size:16px;
  text-transform: uppercase;
}
.custom-btn ul {
  border-radius: 5px;
  padding: 0px;
  margin-top: -5px;
}
.dropdown-item {
  padding: 10px;
}
.custom-btn a{
  /* border-radius: 5px; */
  font-weight: 600;
  background-color: white;
  color:#182983;
  text-transform: capitalize;
}
.dropdown-item:hover {
  background-color: #182983;
  color:white;
  padding: 10px;
}
</style>