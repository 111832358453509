<!-- eslint-disable -->
<template>
<div>
    <!-- <b-modal v-model="showTracker" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Suivre votre colis
      </template>
        <b-form @submit.prevent="login">
          <v-container class="py-0">
          </v-container>
        </b-form>
    </b-modal> -->
    <div class="stick-button">
      <v-btn @click="showSOSOptions = true" style="background-color:#ffe209;color:#0b2963;border-radius:30px;font-size:18px;padding:15px!important;font-weight:bold"><v-icon style="font-size:32px">mdi-tow-truck</v-icon>Urgence</v-btn>
    </div>
    <b-modal v-model="showSOSOptions" hide-footer size="lg">
    <template #modal-title>
      Choisir une option d'urgence
    </template>
    <div style="width:100%;text-align:center">
      <a class="phone-urgent" href="tel:0655516703">
      <v-btn style="background-color:red;border-radius:5px;width:200px">
        <span class="phone-item-item cursor-pointer phone-urgent-item">Appel Urgent</span>
      </v-btn>
      <br>
      <br>
      </a>
      <v-btn @click="execCamionModal" style="background-color:green;border-radius:5px;width:200px">
        Camion Dépannage
      </v-btn>
    </div>
    </b-modal>
    <b-modal modal-class="urgent-modal" v-model="showUrgentCallModal" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title >
        <div>
        Pour livrer votre colis en urgence, veuillez appeler les numéros suivants
        </div>
      </template>
      <div>
        <v-row>
          <b-card style="width:100%;text-align:center;background-color: red;">
            <a href="tel:0655516703" class="phone-urgent"><h1 style="color:white">0655 51 67 03</h1></a>
          </b-card>
        </v-row>
      </div>
    </b-modal>
    <b-modal v-model="showLoginModal" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Se Connecter
      </template>
        <b-form @submit.prevent="login">
          <v-container class="py-0">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field required v-model="email" label="Adresse Email ou Numéro de téléphone" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field required v-model="password" type="password" label="Password" class="purple-input"/>
              </v-col>
              <div style="width:100%">
                  <p v-if="loginMessage.show && loginMessage.status == 'error'" style="color:red">{{loginMessage.message}}</p>
              </div>
              <v-col cols="12" class="text-right">
                <v-btn type="submit"  style="background-color:#4caf50;border-color:#4caf50" class="mr-0">
                  Se Connecter
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </b-form>
    </b-modal>
    <b-modal v-model="showCenterLogin" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Se Connecter
      </template>
      <b-form @submit.prevent="centerLogin">
        <v-container class="py-0">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field required v-model="email" label="Adresse Email ou Numéro de téléphone" class="purple-input"/>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field required v-model="password" type="password" label="Password" class="purple-input"/>
            </v-col>
            <div style="width:100%">
              <p v-if="loginMessage.show && loginMessage.status == 'error'" style="color:red">{{loginMessage.message}}</p>
            </div>
            <v-col cols="12" class="text-right">
              <v-btn type="submit"  style="background-color:#4caf50;border-color:#4caf50" class="mr-0">
                Se Connecter
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </b-form>
    </b-modal>
    <b-modal v-model="showEntrepriseLogin" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Se Connecter
      </template>
        <b-form @submit.prevent="providerlogin">
          <v-container class="py-0">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field required v-model="email" label="Adresse Email ou Numéro de téléphone" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field required v-model="password" type="password" label="Password" class="purple-input"/>
              </v-col>
              <div style="width:100%">
                  <p v-if="loginMessage.show && loginMessage.status == 'error'" style="color:red">{{loginMessage.message}}</p>
              </div>
              <v-col cols="12" class="text-right">
                <v-btn type="submit"  style="background-color:#4caf50;border-color:#4caf50" class="mr-0">
                  Se Connecter
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </b-form>
    </b-modal>
    <b-modal v-model="showCamionModal" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Demander un camion
      </template>
      <b-form @submit.prevent="requestCamion()">
        <v-container class="py-0">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field required class="purple-input" v-model="name" label="Nom de client"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="phone" label="Telephone" placeholder="phone1/phone2"  class="purple-input"/>
            </v-col>
            <v-col cols="12" md="3">
              <label>Wilaya de départ</label>
            <b-form-select required v-model="selectedWilaya1" @change="wilaya = selectedWilaya.name">
              <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
            </b-form-select>
            </v-col>
            <v-col cols="12" md="3">
              <label>Commune</label>
            <b-form-select required v-model="selectedCommunue1" @change="communue = selectedCommunue1">
              <option v-for="communue in selectedWilaya1.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
            </b-form-select>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                id="addr1"
                required 
                v-model="address1" 
                label="Adresse de départ" 
                class="purple-input"/>
            </v-col>
            <v-col cols="12" md="1">
              <v-icon @click="getLocation('addr1')" style="color:green">mdi-flag-triangle</v-icon>
              <p style="font-size:10px">Specifier location</p>
            </v-col>
            <v-col cols="12" md="3">
              <label>Wilaya d'arrivé</label>
            <b-form-select required v-model="selectedWilaya2" @change="wilaya = selectedWilaya.name">
              <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
            </b-form-select>
            </v-col>
            <v-col cols="12" md="3">
              <label>Commune</label>
            <b-form-select required v-model="selectedCommunue2" @change="communue = selectedCommunue2">
              <option v-for="communue in selectedWilaya2.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
            </b-form-select>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field id="addr2" required v-model="address2" label="Adresse d'arrivé" class="purple-input"/>
            </v-col>
            <v-col cols="12" md="1">
              <v-icon @click="getLocation('addr2')" style="color:red">mdi-flag-triangle</v-icon>
              <p style="font-size:10px">Specifier location</p>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="vehicule" label="Nom de Véhicule" class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="matricule" label="Matricule" class="purple-input"/>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="description" label="Description de situation d'urgence (optionnel)" class="purple-input"/>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn type="submit"  style="background-color:#4caf50;border-color:#4caf50" class="mr-0">
                Envoyer
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </b-form>
    </b-modal>
    <b-modal v-model="showMapModal" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Confirmer dans la carte
      </template>
      <div id="map" style="height:400px; width:100%;"></div>
      <v-btn @click="confirmMap" style="background-color:#4caf50;border-color:#4caf50;float:right" class="mr-0">
        Confirmer
      </v-btn>
    </b-modal>
    <b-modal v-model="showPriceCalculator" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Calculer le prix de votre livraison
      </template>
      <b-form @submit.prevent="getFees()">
        <v-container class="py-0">
          <v-row>
            <v-col cols="12" md="6">
              <label>Wilaya de départ</label>
            <b-form-select required v-model="selectedWilaya1" @change="getFees">
              <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
            </b-form-select>
            </v-col>
            <v-col cols="12" md="6">
              <label>Commune</label>
            <b-form-select required v-model="selectedCommunue1" @change="getFees">
              <option v-for="communue in selectedWilaya1.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
            </b-form-select>
            </v-col>
            <v-col cols="12" md="6">
              <label>Wilaya d'arrivé</label>
            <b-form-select required v-model="selectedWilaya2" @change="getFees">
              <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
            </b-form-select>
            </v-col>
            <v-col cols="12" md="6">
              <label>Commune</label>
            <b-form-select required v-model="selectedCommunue2" @change="getFees">
              <option v-for="communue in selectedWilaya2.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
            </b-form-select>
            </v-col>
            <v-col cols="12" md="6">
              <label>Type de transport</label>
              <b-form-select v-model="selectedVehicule" required @change="getFees">
                <option v-for="(vehicule,index) in vehicules" :key="vehicule" :value="index">{{vehicule}}</option>
              </b-form-select>
            </v-col>
            <v-col cols="12" md="4">
              <p style="color:green;margin-top:30px;font-size:18px;">Frais de livraison : {{fais}} DA</p>
            </v-col>
          </v-row>
        </v-container>
      </b-form>
    </b-modal>
    <b-modal v-model="showEntrepriseSignup" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Inscription
      </template>
      <b-form @submit.prevent="addprovider('Logistique')">
        <v-container class="py-0">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field required class="purple-input" v-model="name" label="Nom entreprise"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="semail" label="Adresse Email ou Numéro de téléphone" class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="spassword" label="Password" type="password" class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="phone" label="Telephone" placeholder="phone1/phone2"  class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <label>Wilaya</label>
            <b-form-select required v-model="selectedWilaya" @change="wilaya = selectedWilaya.name">
              <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
            </b-form-select>
            </v-col>
            <v-col cols="12" md="6">
              <label>Commune</label>
            <b-form-select required v-model="selectedCommunue" @change="communue = selectedCommunue">
              <option v-for="communue in selectedWilaya.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
            </b-form-select>
            </v-col>
            <v-col cols="12">
              <v-text-field required v-model="address" label="Adresse" class="purple-input"/>
            </v-col>
            <v-col cols="12" class="text-right">
            <div v-if="emailexist=='error'" style="text-align:center">
              <p style="color:red;">Ce Mail existe déja</p>
            </div>
            <div v-if="emailexist=='success'" style="text-align:center">
              <p style="color:green;">Votre requête à été envoyé</p>
            </div>
              <v-btn type="submit"  style="background-color:#4caf50;border-color:#4caf50" class="mr-0">
                Envoyer
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </b-form>
    </b-modal>
    <b-modal v-model="showCenterSignup" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Inscription
      </template>
      <b-form @submit.prevent="addprovider('Colis Express')">
        <v-container class="py-0">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field required class="purple-input" v-model="name" label="Nom entreprise"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="semail" label="Adresse Email ou Numéro de téléphone" class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="spassword" label="Password" type="password" class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field required v-model="phone" label="Telephone" placeholder="phone1/phone2"  class="purple-input"/>
            </v-col>
            <v-col cols="12" md="6">
              <label>Wilaya</label>
            <b-form-select required v-model="selectedWilaya" @change="wilaya = selectedWilaya.name">
              <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
            </b-form-select>
            </v-col>
            <v-col cols="12" md="6">
              <label>Commune</label>
            <b-form-select required v-model="selectedCommunue" @change="communue = selectedCommunue">
              <option v-for="communue in selectedWilaya.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
            </b-form-select>
            </v-col>
            <v-col cols="12">
              <v-text-field required v-model="address" label="Adresse" class="purple-input"/>
            </v-col>
            <v-col cols="12" class="text-right">
            <div v-if="emailexist=='error'" style="text-align:center">
              <p style="color:red;">Ce Mail existe déja</p>
            </div>
            <div v-if="emailexist=='success'" style="text-align:center">
              <p style="color:green;">Votre requête à été envoyé</p>
            </div>
              <v-btn type="submit"  style="background-color:#4caf50;border-color:#4caf50" class="mr-0">
                Envoyer
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </b-form>
    </b-modal>
    <div class="top-header">
        <div class="alignment-spacer hidden-sm-and-down"></div>
        <v-row class="hidden-sm-and-down">
        <v-col cols="12" md="4">
            <v-btn
                class="ml-2 "
                min-width="0"
                text
            >
                <v-icon style="color:black">mdi-phone-outline</v-icon>
                0655 51 67 03
            </v-btn>
            <v-btn
                class="ml-2 "
                min-width="0"
                text
                style="text-transform:none !important"
            >
                <v-icon style="color:black;">mdi-email-outline</v-icon>
                tlmalgerie@yahoo.com
            </v-btn>
        </v-col>
        <v-col cols="12" md="4">
        </v-col>
        <v-col cols="12" md="4">
            <v-btn
                class="ml-2 "
                min-width="0"
                style="text-transform:none !important"
                text
            >
                Français
            </v-btn>
            <v-btn
                class="ml-2 "
                min-width="0"
                style="text-transform:none !important"
                text
                @click="showLoginModal=true"
                v-b-tooltip.hover title="Espace admin"
            >
                <v-icon style="color:black">mdi-account-outline</v-icon>
                Mon Compte
            </v-btn>
        </v-col>
        <v-col cols="12" md="2">
        </v-col>
        </v-row>
        <v-row class="shown-sm-and-down">
            <v-btn
                style="float:left;text-transform:none !important"
                min-width="0"
                text
            >
                <v-icon style="color:black">mdi-phone-outline</v-icon>
                0655 51 67 03
            </v-btn>
            <v-btn
                min-width="0"
                style="float:right;text-transform:none !important"
                text
                @click="showLoginModal=true"
                v-b-tooltip.hover title="Espace admin"
            >
                <v-icon style="color:black">mdi-account-outline</v-icon>
                Mon Compte
            </v-btn>
        </v-row>
    </div>
    <div class="main-header shown-sm-and-down">
        <div class="alignment-spacer hidden-sm-and-down"></div>
        <v-row>
        <div class="shown-sm-and-down">
          <v-icon @click="toggleShowList" class="phone-list-button" style="font-size:40px;margin:20px 0px 0px 10px;">mdi-view-list</v-icon>
          <router-link to="/" style="margin-top:15px;">
          <img class="vertical-center"
              src="../../assets/mainlogo.png"
              height="30"
          />
          </router-link>
        </div>
      </v-row>
    </div>
    <div class="phone-list">
      <router-link style="color:#ffe209;text-transform:none;font-size:16px" to="/">
      <v-row style="font-size:16px">
        Acceuil
      </v-row>
      </router-link>
      <hr style="background-color:black;">
      <v-row class="side-menu-item-dropdown" style="font-size:16px">
        Présentation
      </v-row>
      <div class="phone-item-list">
          <router-link style="color:white;text-transform:none;" to="/about-us" class="phone-item-item cursor-pointer">Qui Somme nous?</router-link>
          <router-link style="color:white;text-transform:none;" to="/about-us"><span class="phone-item-item cursor-pointer">About us</span></router-link>
      </div>
      <hr style="background-color:black;">
      <v-row class="side-menu-item-dropdown" style="font-size:16px">
        Logistique
      </v-row>
      <div class="phone-item-list">
          <span @click="showEntrepriseSignup=true" class="phone-item-item cursor-pointer">Inscription</span>
          <span @click="showEntrepriseLogin=true" class="phone-item-item cursor-pointer">Se Connecter</span>
          <span @click="showPriceCalculator=true" class="phone-item-item cursor-pointer">Calculer le prix</span>
      </div>
      <hr style="background-color:black;">
      <v-row class="side-menu-item-dropdown" style="font-size:16px">
        TLM Express
      </v-row>
      <div class="phone-item-list">
          <span @click="showTracker=true" class="phone-item-item cursor-pointer">Suivre votre colis</span>
          <span @click="showCenterSignup=true" class="phone-item-item cursor-pointer">Inscription</span>
          <span @click="showCenterLogin=true" class="phone-item-item cursor-pointer">Se Connecter</span>
      </div>
      <hr style="background-color:black;">
      <v-row class="side-menu-item-dropdown" style="font-size:16px">
        TLM SOS
      </v-row>
      <div class="phone-item-list">
          <a class="phone-urgent" href="tel:0655516703"><span class="phone-item-item cursor-pointer phone-urgent-item">Appel Urgent</span></a>
          <span @click="execCamionModal" class="phone-item-item cursor-pointer">Demander un camion</span>
          <!-- <span class="phone-item-item cursor-pointer">Inscription</span>
          <span class="phone-item-item cursor-pointer">Se Connecter</span> -->
      </div>
      <hr style="background-color:black;">
      <v-row style="font-size:16px">
        <router-link style="color:#ffe209;text-transform:none;font-size:16px" to="/contact-us">
          Contact
        </router-link>
      </v-row>
    </div>
    <div class="main-header hidden-sm-and-down">
        <div class="alignment-spacer hidden-sm-and-down"></div>
        <v-row>
        <v-col cols="12" md="3" class="hidden-sm-and-down">
            <router-link to="/">
            <img class="vertical-center"
             style="padding-left:30px"
                src="../../assets/mainlogo.png"
                height="30"
            />
            </router-link>
        </v-col>
        <v-col cols="12" md="6" class="hidden-sm-and-down">
            <div style="width:100%;text-align:center;justify-content:center" class="vertical-center">
            <v-row style="margin:-14px">
            <v-col cols="12" md="2" class="menu-item cursor-pointer">
              <router-link style="font-size:16px;color:black;text-transform:none;text-decoration:none" to="/"> 
                Acceuil
              </router-link>
                <div class="menu-item-bar"></div>
            </v-col>
            <v-col cols="12" md="2" class="menu-item menu-item-dropdown cursor-pointer">
                Présentation
                <div class="menu-item-bar"></div>
                <div class="menu-item-list">
                    <router-link style="color:white;text-transform:none;text-decoration:none" to="/about-us"> <span class="menu-item-item cursor-pointer">Qui Somme nous?</span></router-link>
                    <router-link style="color:white;text-transform:none;text-decoration:none" to="/about-us"> <span class="menu-item-item cursor-pointer">About us</span></router-link>
                </div>
            </v-col>
            <v-col cols="12" md="2" class="menu-item menu-item-dropdown cursor-pointer">
                Logistique
                <div class="menu-item-bar"></div>
                <div class="menu-item-list">
                    <span @click="showEntrepriseSignup=true" class="menu-item-item cursor-pointer">Inscription</span>
                    <span @click="showEntrepriseLogin=true" class="menu-item-item cursor-pointer">Se Connecter</span>
                    <span @click="showPriceCalculator=true" class="phone-item-item cursor-pointer">Calculer le prix</span>
                </div>
            </v-col>
            <v-col cols="12" md="2" class="menu-item menu-item-dropdown cursor-pointer">
                TLM Express
                <div class="menu-item-bar"></div>
                <div class="menu-item-list">
                    <span @click="showTracker=!showTracker" class="menu-item-item cursor-pointer">Suivre votre colis</span>
                    <span v-if="showTracker==true" class="tracker-class">
                      <div class="menu-item-bar" style="background-color:#0b2963;margin-top:0px;border-radius:10px"></div>
                      <b-form @submit.prevent="goToTracking">
                        <input required v-model="tracker" placeholder="Entrer le N° de tracking" style="background-color:#ffe209;color:#0b2963;border:none;outline:none;"/>
                        <v-btn type="submit" style="background-color:#0b2963;border-color:#4caf50;color:#ffe209;height:30px;width:20px">Valider</v-btn>
                      </b-form>
                    </span>
                    <span @click="showCenterSignup=true" class="menu-item-item cursor-pointer">Inscription</span>
                    <span @click="showCenterLogin=true" class="menu-item-item cursor-pointer">Se Connecter</span>
                </div>
            </v-col>
            <v-col cols="12" md="2" class="menu-item menu-item-dropdown cursor-pointer">
                TLM SOS
                <div class="menu-item-bar"></div>
                <div class="menu-item-list">
                    <span class="menu-item-item cursor-pointer phone-urgent-item" @click="showUrgentCallModal=true">Appel Urgent</span>
                    <span @click="execCamionModal" class="menu-item-item cursor-pointer">Demander un camion</span>
                    <!-- <span class="menu-item-item cursor-pointer">Inscription</span>
                    <span class="menu-item-item cursor-pointer">Se Connecter</span> -->
                </div>
            </v-col>
            <v-col cols="12" md="2" class="menu-item cursor-pointer">
              <router-link style="font-size:16px;color:black;text-transform:none;text-decoration:none" to="/contact-us"> 
                Contact
              </router-link>
                <div class="menu-item-bar"></div>
            </v-col>
            </v-row>
            </div>
        </v-col>
        <div style="width:30px" class="hidden-sm-and-down"></div>
        <v-col cols="12" md="2" class="hidden-sm-and-down">
            <a style="text-decoration:none" href="https://www.facebook.com/TLM-1751217361604753/" target="_blank"><v-icon class="vertical-center menu-icon cursor-pointer">mdi-facebook</v-icon></a>
            <a style="text-decoration:none" href="https://www.instagram.com/tlm_algerie/" target="_blank"><v-icon class="vertical-center menu-icon cursor-pointer">mdi-instagram</v-icon></a>
            <v-icon class="vertical-center menu-icon cursor-pointer">mdi-twitter</v-icon>
            <v-icon class="vertical-center menu-icon cursor-pointer">mdi-linkedin</v-icon>
        </v-col>
        </v-row>
    </div>
</div>
</template>
<script>
/* eslint-disable */
import axios from 'axios'
export default {
    data() {
        return {
            showSOSOptions:false,
            vehicules: ['Fourgon 400 KG','Fourgon 1.5 TN', 'Camion 2.5 TN', 'Camion 3.5 TN', 'Camion 10 TN', 'Camion 20 TN'],
            vehicule:null,
            description:null,
            matricule:null,
            showCamionModal:false,
            showUrgentCallModal:false,
            showTracker:false,
            tracker:null,
            wilayas: [],
            communues: [],
            email: null,
            fais:null,
            password:null,
            showEntrepriseLogin: false,
            showCenterLogin: false,
            showEntrepriseSignup:false,
            showPriceCalculator:false,
            showCenterSignup: false,
            showLoginModal:false,
            loginMessage: {
                show:false,
                status: null,
                message: null
            },
            selectedWilaya: {
                name:null,
                communues: []
            },
            selectedCommunue: null,
            selectedWilaya1: {
                name:null,
                communues: []
            },
            selectedCommunue1: null,
            selectedWilaya2: {
                name:null,
                communues: []
            },
            selectedCommunue2: null,
            trackingMessage: {
                show: false,
                status: null,
                message: null
            },
            loginMessage: {
                show: false,
                status: null,
                message: null
            },
            tracking_id: null,
            showPackageModal: false,
            messageWhenNoItems: 'There are not items',
            messagereseved: null,
            cemail: null,
            cname: null,
            telephone: null,
            text: null,
            emailexist: null,
            semail: null,
            spassword: null,
            name: null,
            address: null,
            address1: null,
            address2: null,
            phone: null,
            wilaya: null,
            communue: null,
            email: null,
            auth_token: null,
            password: null,
            postCenters: null,
            selectedPost: 'ANB',
            searchBox1:null,
            searchBox2:null,
            long1:null,
            lat1:null,
            long2:null,
            lat2:null,
            lat:null,
            long:null,
            addrans:null,
            showMapModal:false,
        }
    },
    mounted() {
        axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
        this.getWilayas()
    },
    methods: {
      confirmMap() {
        if (this.addrans == 'addr1') {
          this.lat1 = this.lat
          this.long1 = this.long
        } else if (this.addrans == 'addr2') {
          this.lat2 = this.lat
          this.long2 = this.long
        }
        this.showMapModal = false
      },
      getLocation(addr) {
        this.addrans = addr
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.showPosition, this.showError);
        } else {
          alert("Geolocation is not supported by this browser.");
        }
      },
      showPosition(position) {
        var _this = this;
        function initMap() {
          const uluru = { lat: position.coords.latitude, lng: position.coords.longitude };
          const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 17,
            center: uluru,
          });
          const marker = new google.maps.Marker({
            draggable:true,
            position: uluru,
            map: map,
          });
          marker.addListener('dragend', (event) => {
            _this.lat = event.latLng.lat();
            _this.long = event.latLng.lng();
          });
        }
        this.showMapModal = true
        setTimeout(function() {
          window.initMap = initMap();
        },200);
        if (this.addrans == 'addr1') {
          this.lat1 = position.coords.latitude;
          this.long1 = position.coords.longitude;
        } else if (this.addrans == 'addr2') {
          this.lat2 = position.coords.latitude;
          this.long2 = position.coords.longitude;
        }
      },
      showError(error) {
        switch(error.code) {
          case error.PERMISSION_DENIED:
            alert("User denied the request for Geolocation.")
            break;
          case error.POSITION_UNAVAILABLE:
            alert("Location information is unavailable.")
            break;
          case error.TIMEOUT:
            alert("The request to get user location timed out.")
            break;
          case error.UNKNOWN_ERROR:
            alert("An unknown error occurred.")
            break;
        }
      },
      execCamionModal() {
        this.showCamionModal = true
        // setTimeout(this.googlePlaces,200);
      },
      googlePlaces() {
        let input = document.getElementById('addr1');
        this.searchBox1 = new google.maps.places.Autocomplete(input);
        this.searchBox1.setComponentRestrictions({
          country: ["dz","tn"],
        });
        this.searchBox1.addListener("place_changed", () => {
            const place = this.searchBox1.getPlace();
            if (place.plus_code != undefined) {
              this.address1 = place.plus_code.compound_code
            } else if (!place.adr_address.includes('<')) {
              this.address1 = place.adr_address.split(',')[0]
            } else {
              this.address1 = place.name
            }
        })
        input = document.getElementById('addr2');
        this.searchBox2 = new google.maps.places.Autocomplete(input);
        this.searchBox2.setComponentRestrictions({
          country: ["dz","tn"],
        });
        this.searchBox2.addListener("place_changed", () => {
            const place = this.searchBox2.getPlace();
            if (place.plus_code != undefined) {
              this.address2 = place.plus_code.compound_code
            } else if (!place.adr_address.includes('<')) {
              this.address2 = place.adr_address.split(',')[0]
            } else {
              this.address2 = place.name
            }
        })
      },
      getFees() {
        if (this.selectedWilaya1!=null && this.selectedWilaya2!=null && this.selectedVehicule!=null) {
            axios.post('get-btw', {
            auth_token: localStorage.getItem('auth_token'),
            w1: this.selectedWilaya1.name,
            w2: this.selectedWilaya2.name,
            c1: this.selectedCommunue1,
            c2: this.selectedCommunue2,
            selectedVehicule: this.selectedVehicule,
          }).then(response => {
            if (response.data.status == 'success') {
              console.log(response.data.data)
              this.fais = response.data.data
              let multiple = response.data.multiple
              if (this.isMultiple) {
                this.fais = this.fais + multiple * (this.addr2.length - 1)
              }
              this.responseMessage = null
            } else if (response.data.error_message != undefined) {
              this.responseMessage = response.data.error_message
              this.fais = null
            }
          })
        } else {
          this.fais = null
          this.responseMessage = null
        }
      },
      requestCamion() {
        axios.post('request-camions', {
          sos_data:{
            client_name:this.name,
            client_phone:this.phone,
            wilaya1:this.selectedWilaya1.name,
            wilaya2:this.selectedWilaya2.name,
            communue1:this.selectedCommunue1,
            communue2:this.selectedCommunue2,
            address1:this.address1,
            address2:this.address2,
            lat1:this.lat1,
            long1:this.long1,
            lat2:this.lat2,
            long2:this.long2,
            matricule:this.matricule,
            vehicule:this.vehicule,
            description:this.description
          }
        }).then(response => {
          if (response.data.status == 'success') {
            this.showCamionModal=false
          }
        });
      },
      goToTracking() {
        let routeData =this.$router.resolve({name: 'Tracker', query: {tracking_id: this.tracker}});
        window.open(routeData.href, '_blank');
      },
      toggleShowList() {
        let phoneList = document.getElementsByClassName('phone-list')[0];
        console.log(phoneList.style)
        if (phoneList.style.display == 'none' || phoneList.style.display == '') {
          phoneList.style.display = 'block';
        }
        else if (phoneList.style.display == 'block') {
          phoneList.style.display = 'none';
        } 
      },
      getWilayas() {
        axios.post('get-wilayas').then(response => {
          if (response.data.status == 'success') {
            this.wilayas = response.data.data
          }
        })
      },
      providerlogin() {
        axios.post('login', {
          email: this.email,
          password: this.password
        }).then(response => {
          if (response.data.status =='success') {
            this.auth_token = response.data.data.auth_token
            localStorage.setItem('auth_token',response.data.data.auth_token)
            localStorage.setItem('user_type',response.data.data.type)
            this.$router.push({name:'Dashboard'})
          } else {
              console.log(response.data.error_message)
              this.loginMessage = {
                status: response.data.status,
                message: response.data.error_message,
                show: true
              }
          }
        })
      },
      centerLogin() {
        axios.post('center-login', {
          email: this.email,
          password: this.password
        }).then(response => {
          if (response.data.status =='success') {
            this.auth_token = response.data.data.auth_token
            localStorage.setItem('auth_token',response.data.data.auth_token)
            localStorage.setItem('user_type',response.data.data.type)
            this.$router.push({name:'CenterDashboard'})
            } else {
              console.log(response.data.error_message)
              this.loginMessage = {
                status: response.data.status,
                message: response.data.error_message,
                show: true
              }
            }
        })
      },
      centerLogin() {
        axios.post('center-login', {
          email: this.email,
          password: this.password
        }).then(response => {
          if (response.data.status =='success') {
            this.auth_token = response.data.data.auth_token
            localStorage.setItem('auth_token',response.data.data.auth_token)
            localStorage.setItem('user_type',response.data.data.type)
            this.$router.push({name:'CenterDashboard'})
            } else {
              console.log(response.data.error_message)
              this.loginMessage = {
                status: response.data.status,
                message: response.data.error_message,
                show: true
              }
            }
        })
      },
      login() {
        axios.post('admin-login', {
          email: this.email,
          password: this.password
        }).then(response => {
          if (response.data.status =='success') {
            this.auth_token = response.data.data.auth_token
            localStorage.setItem('auth_token',response.data.data.auth_token)
            localStorage.setItem('user_type',response.data.data.type)
            if (response.data.data.type == 'admin') {
              this.$router.push({name:'AdminDashboard'})
            } else if (response.data.data.type == 'driver') {
             this.$router.push({name:'DriverDashboard'})
            } else if (response.data.data.type == 'depot') {
              this.$router.push({name:'DepotDashboard'})
            } else if (response.data.data.type == 'delivery_man') {
              this.$router.push({name:'DeliveryManDashboard'})
            }
          } else {
              console.log(response.data.error_message)
              this.loginMessage = {
                status: response.data.status,
                message: response.data.error_message,
                show: true
              }
          }
        })
      },
      addprovider(type) {
        axios.post('addprovider',{
          semail: this.semail,
          spassword: this.spassword,
          name: this.name,
          phone: this.phone,
          address: this.address,
          communue: this.communue,
          wilaya: this.wilaya,
          post: this.selectedPost,
          type:type
        }).then(response => {
          this.emailexist = response.data.status
          this.semail=null
          this.spassword=null
          this.name=null
          this.phone=null
          this.address=null
          this.selectedWilaya= {
            name:null,
            communues: []
          }
          this.selectedCommunue=null
          this.selectedPost = null
        })
      },
      handleModalHide() {
        this.showLoginModal = false
      },
    }
}
</script>
<style>
.alignment-spacer{
    background-color:transparent;
    width:100px;
}
.top-header {
    width:100%;
    display:flex;
    height:40px;
    background-color: #ffe209;
}
.main-header {
    width:100%;
    display:flex;
    height:50px;
    background-color: #f7f5e6;
}
.vertical-center {
  -ms-transform: translateY(30%);
  transform: translateY(30%);
}
.menu-item{
    padding-left:0px;padding-right:0px
}
.side-menu-item-dropdown:after {
    content: '\00a0\00a0▸';
}



.menu-item-dropdown:after {
    content: '▾';
}
.menu-item-dropdown:hover:after {
    content: '';
}
.menu-item-list {
    width:100%;
    background-color: #0b2963;
    color:white;
    display: none;
    border-radius: 5px;
}
.menu-item-item {
    display: block;
    padding:10px;
    font-size:11px;
}
.menu-item-item:hover {
    background-color:#0e347d;
}
.menu-item:hover{
    margin:0;
    width:50%;
    font-weight: 700;
    height: 50px;
    background-color: white;
}
.menu-item-bar {
    background-color: yellow;width: 100%;display: none;margin-top: 12px;height: 5px;
}
.menu-item:hover .menu-item-list {
    display: block;
}
.menu-item:hover .menu-item-bar {
    display: block;
}
.menu-icon {
    color:#0b2963 !important;
    padding:3px 7px !important;
}

.menu-icon:hover {
    color:#ffe209 !important;
}

.cursor-pointer {
    cursor : pointer
}



.phone-list {
  display: none;
  background-color:#0b2963;color:#ffe209;width:45%;position:absolute;z-index:2;border-radius:5px;padding:20px 22px;
  /* -webkit-animation: linear 1;
  -webkit-animation-name: run;
  -webkit-animation-duration: 5s; */
}

.phone-item-list {
    width:100%;
    background-color: #0b2963;
    color:white;
    border-radius: 5px;
    margin-top:10px;
}

.phone-item-item {
    font-size:13px;
    display: block;
    padding:5px 10px;
}

.phone-urgent {
  text-transform:none !important;
  text-decoration:none !important;
  color:white !important;
}

.phone-urgent-item {
  background-color: red;
}

.phone-urgent-item:hover {
  background-color: rgb(243, 90, 90);
}

.urgent-modal > .modal-dialog > .modal-content {
  background-color: red;
  color:white
}

.tracker-class {
  position:absolute;margin-top:-40px;margin-left:60px;width:200px;background-color:#ffe209;height:40px;border-radius:10px;
  font-weight: 300;
}
::-webkit-input-placeholder {
  font-weight: 300;
  color:    #0b2963;
}
:-moz-placeholder {
   color:   #0b2963;
   opacity:  1;
}
::-moz-placeholder {
   color:    #0b2963;
   opacity:  1;
}
:-ms-input-placeholder {
   color:    #0b2963;
}
::-ms-input-placeholder {
   color:    #0b2963;
}
::placeholder {
   color:    #0b2963;
}

div.pac-container {
   z-index: 1050 !important;
}

@media (max-width:800px) {
  .stick-button{
    display: block;
    width:100%;
    text-align: right;
    position: fixed;
    float:right;
    height: 10%;
    bottom: 0;
    left: 0;
    z-index:10;
  }
}

</style>